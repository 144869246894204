<template>
  <div>
    <v-skeleton-loader class="mx-auto ml-2 mr-2" type="card" :loading="loading">
      <!-- <v-container fluid class="mb-4"> -->
      <CommunityInfo
        :communityInfo="communityInfo"
        @onRelatedCommunityChanged="showRelatedCommunity"
      />
      <InternalBreadcrumbs :breadcrumbs="breadcrumbs" />
      <v-card elevation="0" outlined :loading="requestProcessing">
        <template slot="progress">
          <v-progress-linear height="10" indeterminate></v-progress-linear>
          <p class="mt-4 text-center">Processing...</p>
        </template>
        <v-card-title v-if="!requestProcessing">
          Quarterly Plan Template
        </v-card-title>
        <v-card-text v-if="!requestProcessing">
          <!-- Start Quarterly Plan section -->
          <v-container>
            <v-row>
              <v-col cols="6" md="3" class="align-self-center">
                <h6>Name: {{ planTemplate.title }}</h6>
                Quarter: Q{{ planTemplate.quarter }}
              </v-col>
            </v-row>

            <!-- <p>
              Add each task individually for each 30 day period. Drag and drop
              any individual task to reorder.
            </p> -->
            <v-row>
              <v-col cols="12" md="8">
                <hr />
                <div>
                  <v-data-table
                    :headers="headers"
                    :items="planTemplate.day30Tasks.$values"
                    item-key="id"
                    :loading="loading"
                    :server-items-length="totalDay30Tasks"
                    :options.sync="options"
                    loading-text="Loading... Please wait"
                    class="elevation-1"
                  >
                    <template v-slot:top>
                      <v-toolbar flat>
                        <v-toolbar-title>{{ month1Name }}</v-toolbar-title>
                        <v-divider class="mx-4" inset vertical></v-divider>
                        <v-spacer></v-spacer>
                        <v-btn
                          color="primary"
                          dark
                          class="mb-2"
                          @click.stop="editItem(null, 30)"
                        >
                          + Add Task
                        </v-btn>
                      </v-toolbar>
                    </template>
                    <template #[`item.actions`]="{ item }">
                      <v-icon small class="mr-2" @click="editItem(item, 30)">
                        mdi-pencil
                      </v-icon>
                      <v-icon small @click="deleteItem(item)">
                        mdi-delete
                      </v-icon>
                    </template>
                  </v-data-table>

                  <h6 class="mt-2">{{ month1Name }} Notes</h6>
                  <v-textarea v-model="planTemplate.day30" solo></v-textarea>
                </div>
                <hr />
                <div>
                  <v-data-table
                    :headers="headers"
                    :items="planTemplate.day60Tasks.$values"
                    item-key="id"
                    :loading="loading"
                    :server-items-length="totalDay60Tasks"
                    :options.sync="options"
                    loading-text="Loading... Please wait"
                    class="elevation-1"
                  >
                    <template v-slot:top>
                      <v-toolbar flat>
                        <v-toolbar-title>{{ month2Name }}</v-toolbar-title>
                        <v-divider class="mx-4" inset vertical></v-divider>
                        <v-spacer></v-spacer>
                        <v-btn
                          color="primary"
                          dark
                          class="mb-2"
                          @click.stop="editItem(null, 60)"
                        >
                          + Add Task
                        </v-btn>
                      </v-toolbar>
                    </template>
                    <template #[`item.actions`]="{ item }">
                      <v-icon small class="mr-2" @click="editItem(item, 60)">
                        mdi-pencil
                      </v-icon>
                      <v-icon small @click="deleteItem(item)">
                        mdi-delete
                      </v-icon>
                    </template>
                  </v-data-table>
                  <v-card class="tasksArea" elevation="0" outlined>
                    <v-card-title> </v-card-title>
                    <v-card-text> </v-card-text>
                  </v-card>
                  <h6 class="mt-2">{{ month2Name }} Notes</h6>
                  <v-textarea v-model="planTemplate.day60" solo></v-textarea>
                </div>
                <hr />

                <div>
                  <v-data-table
                    :headers="headers"
                    :items="planTemplate.day90Tasks.$values"
                    item-key="id"
                    :loading="loading"
                    :server-items-length="totalDay90Tasks"
                    :options.sync="options"
                    loading-text="Loading... Please wait"
                    class="elevation-1"
                  >
                    <template v-slot:top>
                      <v-toolbar flat>
                        <v-toolbar-title>{{ month3Name }}</v-toolbar-title>
                        <v-divider class="mx-4" inset vertical></v-divider>
                        <v-spacer></v-spacer>
                        <v-btn
                          color="primary"
                          dark
                          class="mb-2"
                          @click.stop="editItem(null, 90)"
                        >
                          + Add Task
                        </v-btn>
                      </v-toolbar>
                    </template>
                    <template #[`item.actions`]="{ item }">
                      <v-icon small class="mr-2" @click="editItem(item, 90)">
                        mdi-pencil
                      </v-icon>
                      <v-icon small @click="deleteItem(item)">
                        mdi-delete
                      </v-icon>
                    </template>
                  </v-data-table>
                  <h6 class="mt-2">{{ month3Name }} Notes</h6>
                  <v-textarea v-model="planTemplate.day90" solo></v-textarea>
                </div>
              </v-col>
            </v-row>
          </v-container>
          <!-- End Quarterly Plan section -->
        </v-card-text>
        <v-card-actions v-if="!requestProcessing">
          <v-row>
            <v-col cols="12" md="9" class="text-right">
              <v-btn
                color="primary"
                class="mx-1"
                @click="saveQuarterlyPlanTemplate"
                >Save Quarterly Plan Template</v-btn
              >

              <router-link
                class="blue--text mx-1"
                :to="`/customers/${customerId}/planTemplates`"
              >
                <v-btn>Cancel</v-btn>
              </router-link>
            </v-col>
          </v-row>
        </v-card-actions>
      </v-card>
      <v-dialog v-model="dialog" max-width="500px">
        <v-card>
          <v-card-title>
            <span class="text-h5">{{ formTitle }}</span>
          </v-card-title>

          <v-card-text>
            <v-container>
              <v-row>
                <v-col cols="12">
                  <v-textarea
                    v-model="editedItem.description"
                    label="Task Description"
                  ></v-textarea>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="blue darken-1" text @click="close">
              Cancel
            </v-btn>
            <v-btn color="blue darken-1" text @click="save">
              Save
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-dialog v-model="dialogDelete" max-width="500px">
        <v-card>
          <v-card-title class="text-h5"
            >Are you sure you want to delete this item?</v-card-title
          >
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="blue darken-1" text @click="closeDelete"
              >Cancel</v-btn
            >
            <v-btn color="blue darken-1" text @click="deleteItemConfirm"
              >OK</v-btn
            >
            <v-spacer></v-spacer>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <!-- </v-container> -->
    </v-skeleton-loader>
  </div>
</template>

<script>
import CommunityInfo from "@/view/components/CommunityInfo.vue";
import InternalBreadcrumbs from "@/view/components/DashboardInternalBreadcrumbs.vue";
import { SET_BREADCRUMB } from "@/core/store/breadcrumbs.module";
import {
  GET_QUERY,
  API_CUSTOMERS,
  POST_DATA,
  PUT_DATA,
  API_QUARTERLY_PLAN_TEMPLATES
} from "@/core/store/crud.module";
import crud from "@/core/helpers/crud";
// import dateFormatter from "@/core/helpers/dateFormatter";
import stringFormatter from "@/core/helpers/stringFormatter";
import { validationMixin } from "vuelidate";
// import dataModels from "@/core/helpers/datamodels.js";
// import { required } from "vuelidate/lib/validators";
// import moment from "moment";

export default {
  mixins: [validationMixin],
  components: {
    CommunityInfo,
    InternalBreadcrumbs
  },
  data: () => ({
    headers: [
      {
        text: "Task",
        value: "description",
        sortable: false,
        width: "80%"
      },
      { text: "Actions", value: "actions", sortable: false }
    ],
    communityInfo: {},
    planTemplate: {
      quarter: 1,
      day30: "",
      day60: "",
      day90: "",
      day30Tasks: {
        $values: []
      },
      day60Tasks: {
        $values: []
      },
      day90Tasks: {
        $values: []
      }
    },
    planQuarter: 1,
    planQuarterItems: [
      { name: "Q1", value: "1" },
      { name: "Q2", value: "2" },
      { name: "Q3", value: "3" },
      { name: "Q4", value: "4" }
    ],

    tasks30AddInProcess: false,
    tasks60AddInProcess: false,
    tasks90AddInProcess: false,
    taskContent: "",
    tasksFor30Days: [],
    tasksFor60Days: [],
    tasksFor90Days: [],

    relatedCommunity: null,
    relatedCommunityItems: [],
    startDate: null,

    options: {
      page: 1,
      itemsPerPage: 5
    },
    serviceAlert: {},
    pageText: "",
    startingAfter: "",
    endingBefore: "",
    previousPage: 1,
    hasMore: false,
    isLoading: false,
    loading: false,
    snackbarContent: "",
    snackbarColor: "#F06835",
    breadcrumbs: [],
    requestProcessing: false,
    month1Name: "30 Days",
    month2Name: "60 Days",
    month3Name: "90 Days",
    dialog: false,
    dialogDelete: false,
    editedIndex: -1,
    editedItem: {
      description: "",
      id: 0,
      status: 0,
      period: 0,
      statusValue: "0",
      weight: 1
    },
    defaultItem: {
      description: "",
      id: 0,
      status: 0,
      period: 0,
      statusValue: "0",
      weight: 1
    }
  }),
  props: {
    customerId: {
      type: [Number, String],
      default: 0
    },
    templateId: {
      type: [Number, String],
      default: 0
    }
  },
  validations() {
    return true;
  },
  watch: {
    dialog(val) {
      val || this.close();
    },
    dialogDelete(val) {
      val || this.closeDelete();
    }
  },
  async mounted() {},
  async created() {
    await this.getComunityInfo();
    if (this.templateId) await this.getQuarterlyPlanTemplate();
  },
  methods: {
    editItem(item, period) {
      let array = this.getArrayToManipulate(period);

      if (item) {
        let foundItem = array.find(el => el.id === item.id);
        this.editedIndex = array.indexOf(foundItem);
        this.editedItem = Object.assign({}, item);
      }
      this.editedItem.period = period;
      this.dialog = true;
    },

    deleteItem(item, period) {
      let array = this.getArrayToManipulate(period);
      let foundItem = array.find(el => el.id === item.id);
      this.editedIndex = array.indexOf(foundItem);
      this.editedItem = Object.assign({}, item);
      this.dialogDelete = true;
    },

    deleteItemConfirm() {
      let period = this.editedItem.period;
      let array = this.getArrayToManipulate(period);
      array.splice(this.editedIndex, 1);
      this.closeDelete();
    },

    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    save() {
      let period = this.editedItem.period;
      let array = this.getArrayToManipulate(period);
      if (this.editedIndex > -1) {
        this.$nextTick(() => {
          Object.assign(array[this.editedIndex], this.editedItem);
        });
      } else {
        let min = Math.min(...array.map(i => i.id));
        if (min > 0) min = 0;
        this.editedItem.id = min - 1;
        this.editedItem.weight = 1;
        array.push(this.editedItem);
      }
      this.close();
    },

    getArrayToManipulate(period) {
      let arrayToManipulate = this.planTemplate.day30Tasks.$values;
      if (period === 30) {
        arrayToManipulate = this.planTemplate.day30Tasks.$values;
      }

      if (period === 60) {
        arrayToManipulate = this.planTemplate.day60Tasks.$values;
      }

      if (period === 90) {
        arrayToManipulate = this.planTemplate.day90Tasks.$values;
      }

      return arrayToManipulate;
    },

    showRelatedCommunity(communityId) {
      this.$router.push({
        name: "CustomerDetails",
        params: { customerId: communityId }
      });
    },
    async getComunityInfo() {
      this.loading = true;

      this.isLoading = true;

      await this.$store
        .dispatch(GET_QUERY, {
          listName: API_CUSTOMERS,
          id: this.customerId
        })
        .then(response => {
          if (response.status >= 200 || response.status <= 204) {
            this.communityInfo = crud.getPopulatedComunityInfo(response.data);
            this.createBreadcrumb();
            this.setInternalBreadcrumbs();

            this.planTemplate = {
              quarter: 1,
              day30: "",
              day60: "",
              day90: ""
            };
          } else {
            this.$snackbar.showMessage({
              content: response.data || "Something went wrong!",
              color: "red"
            });
          }

          this.loading = false;
          this.isLoading = false;
        });
    },
    async getQuarterlyPlanTemplate() {
      this.loading = true;

      this.isLoading = true;
      let url = `${API_QUARTERLY_PLAN_TEMPLATES}`;

      if (this.templateId > 0)
        url = `${API_QUARTERLY_PLAN_TEMPLATES}/${this.templateId}`;

      await this.$store
        .dispatch(GET_QUERY, {
          listName: url
        })
        .then(response => {
          this.planTemplate = {
            quarter: 1,

            day30: "",
            day60: "",
            day90: "",

            day30Tasks: {
              $values: []
            },
            day60Tasks: {
              $values: []
            },
            day90Tasks: {
              $values: []
            }
          };
          if (response.status >= 200 || response.status <= 204) {
            this.planTemplate = response.data;

            if (this.planTemplate) {
              if (this.planTemplate.quarter == 1) {
                this.month1Name = "January";
                this.month2Name = "February";
                this.month3Name = "March";
              } else if (this.planTemplate.quarter == 2) {
                this.month1Name = "April";
                this.month2Name = "May";
                this.month3Name = "June";
              } else if (this.planTemplate.quarter == 3) {
                this.month1Name = "July";
                this.month2Name = "August";
                this.month3Name = "September";
              } else if (this.planTemplate.quarter == 4) {
                this.month1Name = "October";
                this.month2Name = "November";
                this.month3Name = "December";
              }

              this.planTemplate.day30Tasks.$values.forEach(element => {
                if (element.status === 0) element.statusValue = "0";
                if (element.status === 1) element.statusValue = "1";
                if (element.status === 2) element.statusValue = "2";
              });

              this.totalDay30Tasks = this.planTemplate.day30Tasks.$values.length;

              this.planTemplate.day60Tasks.$values.forEach(element => {
                if (element.status === 0) element.statusValue = "0";
                if (element.status === 1) element.statusValue = "1";
                if (element.status === 2) element.statusValue = "2";
              });

              this.totalDay60Tasks = this.planTemplate.day60Tasks.$values.length;

              this.planTemplate.day90Tasks.$values.forEach(element => {
                if (element.status === 0) element.statusValue = "0";
                if (element.status === 1) element.statusValue = "1";
                if (element.status === 2) element.statusValue = "2";
              });

              this.totalDay90Tasks = this.planTemplate.day90Tasks.$values.length;
            }
          } else {
            this.$snackbar.showMessage({
              content: response.data || "Something went wrong!",
              color: "red"
            });
          }

          this.loading = false;
          this.isLoading = false;
        });
    },

    async setInternalBreadcrumbs() {
      this.breadcrumbs = [
        {
          title: this.communityInfo.name,
          route: "/customers/" + this.customerId
        },
        {
          title: "Quarterly Plans",
          route: "/customers/" + this.customerId + "/plans"
        },
        {
          title: "Quarterly Plan Templates",
          route: "/customers/" + this.customerId + "/planTemplates"
        },
        { title: "Edit Quarterly Plan Template" }
      ];
    },
    createBreadcrumb() {
      this.$store.dispatch(SET_BREADCRUMB, [
        { title: "Customers", route: "/customers" },
        {
          title: this.communityInfo.name,
          route: "/customers/" + this.customerId
        },
        {
          title: "Quarterly Plans",
          route: "/customers/" + this.customerId + "/plans"
        },
        {
          title: "Quarterly Plan Templates",
          route: "/customers/" + this.customerId + "/planTemplates"
        },
        { title: "Edit Quarterly Plan Templates" }
      ]);
    },
    saveQuarterlyPlanTemplate() {
      this.planTemplate.branch = this.communityInfo.branch.name;

      var self = this;

      this.planTemplate.day30Tasks.$values.forEach(element => {
        if (element.statusValue === "0") element.status = 0;
        if (element.statusValue === "1") element.status = 1;
        if (element.statusValue === "2") element.status = 2;
      });

      this.planTemplate.day60Tasks.$values.forEach(element => {
        if (element.statusValue === "0") element.status = 0;
        if (element.statusValue === "1") element.status = 1;
        if (element.statusValue === "2") element.status = 2;
      });

      this.planTemplate.day90Tasks.$values.forEach(element => {
        if (element.statusValue === "0") element.status = 0;
        if (element.statusValue === "1") element.status = 1;
        if (element.statusValue === "2") element.status = 2;
      });

      this.planTemplate.day30Tasks = this.planTemplate.day30Tasks.$values.map(
        i => {
          return {
            id: i.id > 0 ? i.id : 0,
            description: i.description,
            weight: i.weight,
            status: i.status
          };
        }
      );

      this.planTemplate.day60Tasks = this.planTemplate.day60Tasks.$values.map(
        i => {
          return {
            id: i.id > 0 ? i.id : 0,
            description: i.description,
            weight: i.weight,
            status: i.status
          };
        }
      );

      this.planTemplate.day90Tasks = this.planTemplate.day90Tasks.$values.map(
        i => {
          return {
            id: i.id > 0 ? i.id : 0,
            description: i.description,
            weight: i.weight,
            status: i.status
          };
        }
      );

      let url = `${API_QUARTERLY_PLAN_TEMPLATES}`;
      let action = POST_DATA;
      if (this.templateId) {
        url = `${API_QUARTERLY_PLAN_TEMPLATES}/${this.templateId}`;
        action = PUT_DATA;
      }

      this.requestProcessing = true;

      this.$store
        .dispatch(action, {
          listName: url,
          data: this.planTemplate
        })
        .then(response => {
          if (response) {
            self.snackbarContent =
              "Quarterly Plan Template has been saved successfully";
            self.snackbarColor = "green";
            self.$router.push({
              name: "CustomerQuarterlyPlanTemplates",
              params: { customerId: self.customerId }
            });
          } else {
            self.snackbarContent = "Something went wrong!"; //response.data;
            self.snackbarColor = "red";
          }
          self.$snackbar.showMessage({
            content: self.snackbarContent,
            color: self.snackbarColor
          });

          self.confirmDialog = self;
          self.requestProcessing = false;
        })
        .catch(err => {
          self.$snackbar.showMessage({
            content: err,
            color: self.snackbarColor
          });
          self.requestProcessing = false;
        });
    },
    async submit() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        return;
      }
    },
    capitalizeFirstLetter(string) {
      return stringFormatter.capitalizeFirstLetter(string);
    },
    formatDate(date) {
      if (!date) return null;

      const [year, month, day] = date.split("-");
      return `${month}/${day}/${year}`;
    },
    parseDate(date) {
      if (!date) return null;

      const [month, day, year] = date.split("/");
      return `${year}-${month.padStart(2, "0")}-${day.padStart(2, "0")}`;
    }
  },
  computed: {
    formTitle() {
      let monthName = this.month1Name;
      if (this.editedItem.period === 30) monthName = this.month1Name;
      else if (this.editedItem.period === 60) monthName = this.month2Name;
      else if (this.editedItem.period === 90) monthName = this.month3Name;

      return this.editedIndex === -1
        ? monthName + " New Item"
        : "Edit " + monthName + " Item";
    }
  }
};
</script>
